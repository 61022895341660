import { render, staticRenderFns } from "./groupTest.vue?vue&type=template&id=968c657a&scoped=true&"
import script from "./groupTest.vue?vue&type=script&lang=js&"
export * from "./groupTest.vue?vue&type=script&lang=js&"
import style0 from "./groupTest.vue?vue&type=style&index=0&id=968c657a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "968c657a",
  null
  
)

export default component.exports