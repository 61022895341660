<template>
  <div class="main">
    <div class="title">组卷测试</div>
    <el-form :model="ruleForm"
             ref="ruleForm"
             label-width="100px"
             class="demo-ruleForm">
      <el-form-item label="科目"
                    style="width: 20%;"
                    prop="subject_ids">
        <el-select style="width:100%"
                   v-model="ruleForm.subject_ids"
                   clearable
                   @change="change"
                   placeholder="选择科目">
          <el-option v-for="item in subjectList"
                     :key="item.subject_id"
                     :label="item.subject_name"
                     :value="item.subject_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模版"
                    style="width: 20%;"
                    prop="paper_district_id">
        <el-select style="width:100%"
                   v-model="ruleForm.paper_district_id"
                   clearable
                   placeholder="选择模版">
          <el-option v-for="item in paperList"
                     :key="item.paper_district_id"
                     :label="item.paper_district_name"
                     :value="item.paper_district_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试卷数量"
                    style="width: 20%;">
        <el-input-number v-model="ruleForm.num"
                         @change="handleChange"
                         :min="1"
                         :max="5"
                         label="描述文字"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="goback()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetectionList, createPaper, getCpSubjectTmp } from '@/api/dataAnalysis.js'
export default {
  data () {
    return {
      ruleForm: {
        subject_ids: '',
        paper_district_id: '',
        detection_id: '',
        num: 0,
      },
      rules: {
        paper_district_id: [
          { required: true, message: '请输入检测报告', trigger: 'blur' },
        ],
        subject_ids: [
          { required: true, message: '请选择科目', trigger: 'change' }
        ],
      },
      subjectList: [],
      paperList: []
    };
  },
  async created () {
    const { data } = await getDetectionList({ detection_id: this.$route.query.detection_id })
    this.subjectList = data.list
  },
  mounted () {
    // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
    // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
    // // document.getElementsByClassName('view_warp')[0].style.background = '#FFFFFF'
    // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
    // document.getElementsByClassName('view_warp')[0].style.marginTop = '34px'
    // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '110px'
    // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'

  },
  methods: {
    goback () {
      this.$router.go(-1)
    },
    async change (val) {
      this.ruleForm.paper_district_id = ''
      const { data } = await getCpSubjectTmp({ subject_id: val, detection_id: this.$route.query.detection_id })
      this.paperList = data
    },
    submitForm (formName) {
      this.$refs[formName].model.detection_id = this.$route.query.detection_id
      this.$refs[formName].validate((valid) => {
        let params = {

        }
        params.paper_district_id = this.$refs[formName].model.paper_district_id
        params.detection_id = Number(this.$refs[formName].model.detection_id)
        params.subject_id = this.$refs[formName].model.subject_ids
        params.num = Number(this.$refs[formName].model.num)
        if (valid) {
          this.$http({
            url: '/api/v1/tang/create',
            method: 'post',
            data: params
          }).then((res) => {
            if (res.data.error_num > 0) {
              this.$notify({
                title: '提示',
                message: `提交失败${res.data.error_num}份试卷!`,
                type: 'error'
              });
            } else {
              this.$notify({
                title: '提示',
                message: `提交成功${this.$refs[formName].model.num - res.data.error_num}份试卷!`,
                type: 'success'
              });
              this.$router.go(-1)
            }

            // this.$router.push(
            //   {
            //     path: '/dataAnalysis/viewTestPaper/view',
            //     query: {
            //       user_paper_id: res.data.list.user_paper_id,
            //       // subject_name: this.$refs[formName].model.subject_name,
            //       // paper_district_name: row.paper_district_name
            //     }
            //   })
          }).catch(error => {


            this.$notify({
              title: '提示',
              message: '提交失败!',
              type: 'error'
            });

          });
        } else {
          this.$notify({
            title: '提示',
            message: '提交失败!',
            type: 'error'
          });
          return false;
        }
      });
    },
    // async postData (params) {
    //   await createPaper(params)
    // },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  font-family: Regular;
  padding: 12px;
  background: white;
  .title {
    top: 142px;
    left: 256px;
    width: 80px;
    height: 30px;
    // background: rgba(17, 17, 17, 1);
    margin: 8px 0px 30px 12px;
    color: rgba(17, 17, 17, 1);
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}
::v-deep .el-form-item__label {
  font-size: 14px !important;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
::v-deep .el-button {
  padding: 0;
  width: 60px;
  height: 32px;
  margin-top: 16px;
}
</style>